<template>
  <div class="container mx-auto py-16 relative">
    <transition
      enter-active-class="miniFadeInTop"
      leave-active-class="miniFadeOutBottom"
      mode="out-in"
    >
      <router-view name="page" :feedback="feedback" />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feedback: {
        stepOne: "",
        stepTwo: ""
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
